<template>
  <div>
    <div class="d-flex justify-content-start align-items-center mb-3">
      <!-- <a-input style="width: 200px" v-model:value="search" placeholder="Cari Course...">
        <template #prefix>
          <span>
            <search-outlined style="color: rgba(0, 0, 0, 0.25)" />
          </span>
        </template>
      </a-input> -->
      <a-button @click.prevent="modalToggle" type="primary">
        <template #icon><plus-outlined /></template>
        Tambahkan Akses Course
      </a-button>
      <a-modal v-model:visible="modalVisible" title="Form Tambah Akses Course" @ok="handleOkModal">
        <template #footer>
          <a-button key="back" @click="modalToggle">batal</a-button>
          <a-button key="submit" type="primary" :loading="loadingModal" @click="handleOkModal"
            >Tambahkan</a-button
          >
        </template>
        <a-form layout="vertical" :model="form">
          <a-form-item label="Pilih Course">
            <a-select class="w-100" @change="handleChange">
              <a-select-option
                v-for="course in courseAvalaible"
                :key="course._id"
                :value="course._id"
                >{{ course.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
    <a-table
      :columns="courseColumn"
      :data-source="courseDataTable"
      :loading="courseLoading"
      bordered
    >
      <template #type="{ text }">
        <a-tag :color="text === 'free' ? 'green' : text === 'premium' ? 'purple' : 'red'">{{
          text === 'free' ? 'Gratis' : text === 'premium' ? 'Berbayar' : '-'
        }}</a-tag>
      </template>
      <template #action="{ record }">
        <button @click.prevent="onRemoveAccess(record)" class="btn btn-outline-danger">
          Hapus Akses
        </button>
      </template>
    </a-table>
  </div>
</template>

<script>
import moment from 'moment'
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { ref } from '@vue/reactivity'
import { createVNode, onMounted } from '@vue/runtime-core'
import { useStore } from 'vuex'
import { Modal, notification } from 'ant-design-vue'
import { useRoute } from 'vue-router'
const courseColumn = [
  {
    title: 'Nama Course',
    dataIndex: 'name',
    key: 'name',
    slots: { customRender: 'name' },
  },
  {
    title: 'Tipe Course',
    dataIndex: 'type',
    key: 'type',
    slots: { customRender: 'type' },
    align: 'center',
  },
  {
    title: 'Tanggal Bergabung',
    dataIndex: 'lastUpdate',
    key: 'lastUpdate',
    slots: { customRender: 'lastUpdate' },
  },
  {
    title: 'Aksi',
    key: 'action',
    slots: { customRender: 'action' },
    align: 'center',
  },
]
export default {
  components: {
    // SearchOutlined,
    PlusOutlined,
  },
  props: {
    userId: {
      type: String,
      default: () => { return '' },
    },
  },
  setup(props) {
    const store = useStore()
    const route = useRoute()
    let courseDataTable = ref([])
    let courseAvalaible = ref([])
    let search = ref(null)
    let modalVisible = ref(false)
    let courseLoading = ref(false)
    let loadingModal = ref(false)
    let form = ref({
      courseId: null,
    })

    const filterOption = (input, option) => {
      console.log(option);
      return option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    const handleChange = value => {
      form.value.courseId = value
    };

    const fetchMyCourse = (async () => {
      courseLoading.value = true
      const data = await store.dispatch('akun/FETCH_MYCOURSE', props.userId)
      courseDataTable.value = data.map(el => {
        return {
          ...el,
          key: el._id,
          lastUpdate: moment(el.lastUpdate).locale('id').format('DD MMMM YYYY HH:mm:ss'),
        }
      })
      courseLoading.value = false
    })

    const onRemoveAccess = ((record) => {
      Modal.confirm({
        title: 'Apakah anda yakin ingin menghapus akses course?',
        icon: createVNode(ExclamationCircleOutlined),
        content: 'Seluruh data akses (beserta progres belajar) akan dihapus selamanya',
        okText: 'Hapus',
        okType: 'danger',
        cancelText: 'Batal',
        onOk() {
          const id = record.key
          store.dispatch('akun/TAKEDOWN_MYCOURSE', id)
            .then(res => {
              notification.success({
                message: 'Penghapusan Akses Course Berhasil',
              });
            })
            .catch(err => {
              notification.error({
                message: 'Penghapusan Akses Course Gagal',
              });
            })
          fetchMyCourse()
        },
        onCancel() {
          console.log('Cancel')
        },
      });
    })

    const fetchAllPublishedCourse = async () => {
      const courseExcludes = courseDataTable.value.map(course => {
        return course.courseId
      }) || []
      console.log(courseExcludes)
      courseAvalaible.value = await store.dispatch('course/FETCH_ALL_PUBLISHED_WITH_EXCEPT_COURSE', courseExcludes) || []
    }

    const modalToggle = (() => {
      if (!modalVisible.value) {
        fetchAllPublishedCourse()
      }
      modalVisible.value = !modalVisible.value
    })

    const handleOkModal = () => {
      if (!form.value.courseId) {
        return notification.warning({
          message: 'Course wajib diisi',
        });
      }

      Modal.confirm({
        title: 'Apakah anda yakin ingin memberikan akses course?',
        icon: createVNode(ExclamationCircleOutlined),
        content: 'Seluruh data akses (beserta progres belajar) akan diberikan selamanya',
        okText: 'Tambahkan Akses Course',
        okType: 'primary',
        cancelText: 'Batal',
        onOk() {
          store.dispatch('course/JOIN_COURSE_ADMIN', { payload: { ...form.value, customerId: props.userId }  })
            .then(res => {
              notification.success({
                message: 'Penambahan Akses Course Berhasil',
              });
              modalToggle()
              fetchMyCourse()
            })
            .catch(err => {
              notification.error({
                message: 'Penambahan Akses Course Gagal',
              });
            })
        },
        onCancel() {
          console.log('Cancel')
        },
      });

    }

    onMounted(() => fetchMyCourse())

    return {
      search,
      form,
      filterOption,
      handleChange,
      courseColumn,
      courseAvalaible,
      courseDataTable,
      courseLoading,
      loadingModal,
      modalVisible,
      modalToggle,
      handleOkModal,
      onRemoveAccess,
    }
  },
}
</script>

<style>
</style>
